


import React, {  useEffect } from 'react';
import {


  Grid,
  InputLabel,

  MenuItem,

  Select,


} from '@mui/material';
import { mesesNumero } from '../../../data/makeDataCrud';


export const MesAnio = ({valueMes,setValueMes,valueAno,setValueAno,valueAAno,setValueAAno}) => {
  //desde el año anterior
  const anoList = () => {

    const d = new Date();
    const n = d.getFullYear();

    for (let i = n; i >= d.getFullYear() - 1; i--) {


      const obano = { id: i, ano: i.toString() };
      console.log("obano: ", obano);
      valueAAno.push(obano);


      setValueAAno([...valueAAno]);

    };
    
  };



  const handleChangeMes = (event) => {
    console.log(event.target.value);
    setValueMes(event.target.value);
    
  };


 const handleChangeAno = (event) => {
    console.log(event.target.value);
    setValueAno(event.target.value);
   
  };
  useEffect(() => {


    anoList();

  }, []);
  return (

    <Grid container spacing={2}>

      <Grid  item xs={0}>
        <InputLabel id="meses-numero">Mes</InputLabel>
        <Select
          labelId="meses-numero"
          id="meses-numero"
          label="Mes"
          value={valueMes || ""}
          onChange={handleChangeMes}
        >
          {mesesNumero.map(option => (
            <MenuItem key={option.id} value={option.mes}>
              {option.mes}
            </MenuItem>
          ))}
        </Select>
      </Grid>

      <Grid item xs={1}>
          <InputLabel id="ano-numero">Año</InputLabel>
          <Select
            labelId="ano-numero"
            id="ano-numero"
            label="Ano"
            value={valueAno || ""}
            onChange={handleChangeAno}
          >
          {valueAAno.map(option => (
            <MenuItem key={option.id} value={option.ano}>
              {option.ano}
            </MenuItem>
          ))}
          </Select>
          </Grid>          
    </Grid>
  )
};

export default MesAnio;
  
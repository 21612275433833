import { useState ,useEffect} from 'react';



import { useFormik } from 'formik'
import * as yup from 'yup'
import {
    Alert,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    MenuItem,
    Stack,
    TextField,
   Typography
  
  } from '@mui/material';

  import { onlyNumbers } from '../helpers/funcYup';
  import {esUnicoYup,existeCodigoYup} from '../helpers/funcYup';

  yup.addMethod(yup.string, "solonum", function (errorMessage) {
    return this.test(`test-codigo solo num`, errorMessage, function (value) {
      const { path, createError } = this;
  
      return (
        (value && onlyNumbers(value)) ||
        createError({ path, message: errorMessage })
      );
    });
  });


  
  export const ModalLibroCompra =({ open,
     onClose, 
     onSubmit,
     onEdit,
     documentoData,
     operacionData,
     inicial,
     titulomod,
     }) => {

    const [errorPrueba, setErrorPrueba] = useState(false);
    const [errorText,setErrorText] = useState("");
   // const [valuessector, setValuesSector] = useState(""); 
    const [valuesdocumento, setValuesDocumento] = useState("");  
    const [valuesoperacion, setValuesOperacion] = useState("");
    const [initValues] = useState(inicial);



    const checkoutSchema = yup.object().shape({
      codigo: yup.string(),
      documento: yup.string("Enter documento").required("is required"),
      operacion: yup.string("Enter documento").required("is required")
    });

    const formik = useFormik({
      
      initialValues:initValues,
      validationSchema: checkoutSchema ,
      onSubmit: async (values) => {
        if (values.id==="new" ){

            onSubmit(values);
            onClose();
            /////////////// 
           
        }else{

            //////////////submit 
            console.log("formik edit============>"); 
            console.log(values); 
            onEdit(values);
            onClose();
            
            /////////////// 

        }

        formik.resetForm();
 
      },
    });


    const handleChangeDocumento= name => event => {

      console.log(name);
      const vnombre=event.target.value;
      //console.log(vnombre);
      console.log(event);
      setValuesDocumento(vnombre);
      
      formik.setFieldValue("documento", vnombre);
      
    };

    const handleChangeOperacion= name => event => {

      console.log(name);
      const vnombre=event.target.value;
      //console.log(vnombre);
      console.log(event);
      setValuesOperacion(vnombre);
      
      formik.setFieldValue("operacion", vnombre);
      
    };    
    // const handleCodigoChange = (event) => {
    //   formik.handleChange(event);

    //   console.log(event.target.value);
      
    //   if(valueIdyup.id === "new"){
    //        console.log("validando");

    //       if (event.target.value.length === 3 && existeCodigoYup(event.target.value,valueIdyup,tableData)){
    //         setErrorPrueba(true);
    //         setErrorText(`Ya existe ${event.target.value}`);
    //       }else{
    //         setErrorPrueba(false);
            
    //       }
         
    //   }

    // };
  
    useEffect(() => {
      console.log("Valores iniciales =====>",inicial);
  
      formik.setFieldValue("id", inicial.id);


      formik.setFieldValue("documento",inicial.documento.descripcion);
      formik.setFieldValue("operacion",inicial.operacion.descripcion);

      setValuesDocumento(inicial.documento.descripcion);
      setValuesOperacion(inicial.operacion.descripcion);
      setErrorPrueba(false);
      
    
    }, [open]);// eslint-disable-line react-hooks/exhaustive-deps
   
    return (
      <>
        
        <Dialog open={open} maxWidth="md" >
          
          <DialogTitle textAlign="center" > 
         
                 <div style={{ display: 'flex' }}>
                    <Typography variant="h6" component="div" style={{ flexGrow: 1 }}>
                         {titulomod}
                    </Typography>

                </div>
        
                {  errorPrueba   &&
                  <Alert onClose={() => setErrorPrueba(false)} variant="outlined" severity="error">
                     {errorText} alerta — revisar!
                  </Alert>
                }  
          </DialogTitle>
           <DialogContent dividers >
            <form onSubmit={formik.handleSubmit}>
                      <Stack
                      sx={{
                        width: '100%',
                        minWidth: { xs: '300px', sm: '360px', md: '400px' },
                        gap: '1.5rem',
                      }}
                    >
                      {/* <TextField
                        disabled={deshabilitado}
                        fullWidth
                        margin="normal" 
                        id="codigo"
                        name="codigo"
                        label="codigo"
                        autoComplete='off'
                        value={formik.values.codigo}
                        onChange={handleCodigoChange}
                        error={formik.touched.codigo && Boolean(formik.errors.codigo)}
                        helperText={formik.touched.codigo&& formik.errors.codigo}
                      />
                    
                      <TextField
                        fullWidth
                        margin="normal" 
                        id="descripcion"
                        name="descripcion"
                        label="descripcion"
                        autoComplete='off'
                        value={formik.values.descripcion}
                        onChange={formik.handleChange}
                        error={formik.touched.descripcion && Boolean(formik.errors.descripcion)}
                        helperText={formik.touched.descripcion && formik.errors.descripcion}
                      /> */}

                      {/* <TextField
                        fullWidth
                        margin="normal" 
                        id="standard-select-sector"
                        select
                        name="sector"
                        label="sector"
                       
                        value={valuessector}
                        onChange={handleChangeSector("ciudad")}
                        error={formik.touched.sector && Boolean(formik.errors.sector)}
                        helperText={formik.touched.sector && formik.errors.sector}
                        
                      >
                        {sectorData.map(option => (
                          <MenuItem key={option.id} value={option.descripcion}>
                              {option.descripcion}
                          </MenuItem>
                        ))}
                      </TextField> */}

                      <TextField
                        fullWidth
                        margin="normal" 
                        id="standard-select-documento"
                        select
                        name="doc"
                        label="documento"
                       
                        value={valuesdocumento}
                        onChange={handleChangeDocumento()}
                        error={formik.touched.documento && Boolean(formik.errors.documento)}
                        helperText={formik.touched.documento && formik.errors.documento}
                        
                      >
                        {documentoData.map(option => (
                          <MenuItem key={option.id} value={option.descripcion}>
                              {option.descripcion}
                          </MenuItem>
                        ))}
                      </TextField>                     

                      <TextField
                        fullWidth
                        margin="normal" 
                        id="standard-select-operacion"
                        select
                        name="ope"
                        label="operacion"
                       
                        value={valuesoperacion}
                        onChange={handleChangeOperacion()}
                        error={formik.touched.operacion && Boolean(formik.errors.operacion)}
                        helperText={formik.touched.operacion && formik.errors.operacion}
                        
                      >
                        {operacionData.map(option => (
                          <MenuItem key={option.id} value={option.descripcion}>
                              {option.descripcion}
                          </MenuItem>
                        ))}
                      </TextField>                      
                    
                    </Stack>
                      <DialogActions sx={{ p: '1.25rem' }}>
                        <Button color="secondary" onClick={onClose}>Cancel</Button>
                        <Button color="primary" type="submit" variant="contained">
                          Submit
                        </Button>
                       </DialogActions>
                 
                </form>
           </DialogContent>
        </Dialog>
      </>
    );
  };
  
  
  export default  ModalLibroCompra;
  
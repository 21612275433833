import React, { useMemo, useState, useEffect, useContext } from 'react';
import axios from "axios";




import Header from "../../../components/Header";


import MaterialReactTable,
{
  MRT_FullScreenToggleButton,
  MRT_ShowHideColumnsButton,
  MRT_ToggleGlobalFilterButton,
} from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import {
  Alert,
  Box,
  Button,
  Grid,
  Typography
} from '@mui/material';

import Snackbar from '@mui/material/Snackbar';
import { Send } from '@mui/icons-material';

import CloudDownload from "@mui/icons-material/CloudDownload";
import Print from "@mui/icons-material/Print";
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { ExportToCsv } from 'export-to-csv'; //or use your library of choice here
import { ActionIcon } from '@mantine/core';

//import { inicialOpcion } from '../../../data/makeDataCrud';
//import { ModalGen } from '../modal/ModalGen';

//import esUnico from '../helpers/esUnico';
import { AuthContext } from "../../../mycontext";


import MesAnio from '../components/MesAnio';
//import DocumentChecks from '../components/DocumentChecks';
import DocumentTag from '../components/DocumentTag';
import { mesesNumero } from '../../../data/makeDataCrud';


const URL_BASE = process.env.REACT_APP_URL_BASE;
// const API_SEL = process.env.REACT_APP_API_SEL_OPC;
// const API_INS = process.env.REACT_APP_API_INS_OPC;
// const API_UPD = process.env.REACT_APP_API_UPD_OPC;
// const API_DEL = process.env.REACT_APP_API_DEL_OPC;

//parametro libro
const API_SEL_LCO = process.env.REACT_APP_API_SEL_LCO;

//documentos libro
const API_SEL_EDO_All_REL = process.env.REACT_APP_API_SEL_EDO_All_REL;
const APP_API_SEL_EDO_PER = process.env.REACT_APP_API_SEL_EDO_PER;


//parametros
const API_SEL_PAR = process.env.REACT_APP_API_SEL_PAR;

//documentos
const API_SEL_DOC=process.env.REACT_APP_API_SEL_DOC;

const InformeTipoDocumento = () => {

  const { user } = useContext(AuthContext);
  const { token } = user;

  const [titulomod, setTitulomod] = useState('');

  const [title, setTitle] = useState('');

  const [subTitle, setSubTitle] = useState('');

  const [isRefetching, setIsRefetching] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [errorPrueba, setErrorPrueba] = useState(false);

  const [errorText, setErrorText] = useState("");

  const [documentName, setDocumentName] = useState([]);

  const [entName, setEntName] = useState([]);

  const [tableData, setTableData] = useState([]);

  const [tableDataResumen, setTableDataResumen] = useState([]);

  const [tableParametroLibro, setTableParametroLibro] = useState([]);

  // const [rowData, setRowData] = useState([]);

  // const [inicial, setInicial] = useState(inicialOpcion);

  // const [deshabilitado, setDeshabilitado] = useState(false);

  const [valueAno, setValueAno] = useState("");

  const [valueMes, setValueMes] = useState("");
  const [valueAAno, setValueAAno] = useState([]);
  const [valueParametroEmpresa, setValueParametroEmpresa] = useState("");


  const handleChangeMes = (event) => {
    console.log(event.target.value);
    setValueMes(event.target.value);

  };

  //snack
  const [state] = useState({
    vertical: 'top',
    horizontal: 'center',
  });

  const { vertical, horizontal } = state;
  const [openSnack, setOpenSnack] = useState(false);
  const [message, setMessage] = useState(false);

  const existeCodigo = (code) => {
    return tableData.find(({ codigo }) => codigo === code);
  };


  const names = [
    'Oliver Hansen',
    'Van Henry',
    'April Tucker',
    'Ralph Hubbard',
    'Omar Alexander',
    'Carlos Abbott',
    'Miriam Wagner',
    'Bradley Wilkerson',
    'Virginia Andrews',
    'Kelly Snyder',
  ];
  const columns = useMemo(
    () => [
      {
        accessorKey: 'id',
        header: 'ID',
        enableColumnOrdering: false,
        enableEditing: false, //disable editing on this column
        enableSorting: false,
        size: 80,
      },
      {
        accessorKey: 'documento.descripcion',
        header: 'documento',
        size: 80,

      },
      {
        accessorKey: 'foliodoc',
        header: 'foliodocumento',
        size: 40,

      },
      {
        accessorKey: 'fechadoc',
        header: 'fechadoc',
        size: 40,

      },

      {
        accessorKey: 'proveedor.razonsocial',
        header: 'proveedor',
        size: 80,

      },
      {
        accessorKey: 'exento',
        header: 'exento',
        size: 40,

      },
      {
        accessorKey: 'neto',
        header: 'neto',
        size: 40,

      },
      {
        accessorKey: 'siva',
        header: 'iva',
        size: 40,

      },
      {
        accessorKey: 'sila',
        header: 'ila',
        size: 40,

      },
      {
        accessorKey: 'sretencion',
        header: 'sretencion',
        size: 40,

      },
      {
        accessorKey: 'sdescuento',
        header: 'suma descuento',
        size: 40,

      },
      {
        accessorKey: 'descuentoglobal',
        header: 'descuento global',
        size: 40,

      },
      {
        accessorKey: 'srecargo',
        header: 'suma recargo',
        size: 40,

      },
      {
        accessorKey: 'recargoglobal',
        header: 'recargo global',
        size: 40,

      },

      {
        accessorKey: 'total',
        header: 'total',
        size: 40,

      }
    ],
    [],
  );

  const columnsResumen = useMemo(
    () => [
      // {
      //   accessorKey: 'id',
      //   header: 'ID',
      //   enableColumnOrdering: false,
      //   enableEditing: false, //disable editing on this column
      //   enableSorting: false,
      //   size: 40,
      // },
      {
        accessorKey: 'cantidad',
        header: 'cantidad',

        size: 40,
      },
      {
        accessorKey: 'documento.descripcion',
        header: 'documento',
        size: 40,

      },
      {
        accessorKey: 'exento',
        header: 'exento',
        size: 40,

      },
      {
        accessorKey: 'neto',
        header: 'neto',
        size: 40,

      },
      {
        accessorKey: 'siva',
        header: 'iva',
        size: 40,

      },
      {
        accessorKey: 'sila',
        header: 'ila',
        size: 40,

      },
      {
        accessorKey: 'sretencion',
        header: 'sretencion',
        size: 40,

      },
      {
        accessorKey: 'sdescuento',
        header: 'suma descuento',
        size: 40,

      },
      {
        accessorKey: 'descuentoglobal',
        header: 'descuento global',
        size: 40,

      },
      {
        accessorKey: 'srecargo',
        header: 'suma recargo',
        size: 40,

      },
      {
        accessorKey: 'recargoglobal',
        header: 'recargo global',
        size: 40,

      },

      {
        accessorKey: 'total',
        header: 'total',
        size: 40,

      }
    ],
    [],
  );


  const handleClose = (reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnack(false);

  };

  //const columnsCsv=['First Name','Last Name','Addres','City','State']
 
  const csvOptions = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: false,
    headers: columns.map((c) => c.header),
  };

  const csvExporter = new ExportToCsv(csvOptions);

  const handleExportData = () => {
    let dataCsv = [];

    for (let i = 0; i < tableData.length; i++) {

      const fila = {
        id:tableData[i].id,
        documento: tableData[i].documento.descripcion,
        foliodoc: tableData[i].foliodoc,
        fechadoc: tableData[i].fechadoc,
        proveedor: tableData[i].proveedor.razonsocial,
        exento: tableData[i].exento,
        neto:tableData[i].neto,
        siva:tableData[i].siva,
        sila:tableData[i].sila,
        sretencion:tableData[i].sretencion,
        sdescuento:tableData[i].sdescuento,
        descuentoglobal:tableData[i].descuentoglobal,
        srecargo:tableData[i].srecargo,
        recargoglobal:tableData[i].recargoglobal,
        total:tableData[i].total,
      };
      dataCsv = [...dataCsv, fila];

    };

    csvExporter.generateCsv(dataCsv);
  };


  const get_data_to_pdf = () => {
    let dataPdf = [];

    for (let i = 0; i < tableData.length; i++) {

      const fila = [
        tableData[i].id,
        tableData[i].documento.descripcion,
        tableData[i].foliodoc,
        tableData[i].fechadoc,
        tableData[i].proveedor.razonsocial,
        tableData[i].exento,
        tableData[i].neto,
        tableData[i].siva,
        tableData[i].sila,
        tableData[i].sretencion,
        tableData[i].sdescuento,
        tableData[i].descuentoglobal,
        tableData[i].srecargo,
        tableData[i].recargoglobal,
        tableData[i].total,
      ];
      dataPdf = [...dataPdf, fila];

    };

    return dataPdf;

  }

  const get_column_to_pdf = () => {
    let columnPdf = [];

    for (let i = 0; i < columns.length; i++) {

      columnPdf = [...columnPdf, columns[i].header];

    };

    return columnPdf;
  }

  const get_data_to_pdf_resumen = () => {
    let dataPdf = [];

    for (let i = 0; i < tableDataResumen.length; i++) {

      const fila = [
        tableDataResumen[i].cantidad,
        tableDataResumen[i].documento.descripcion,

        tableDataResumen[i].exento,
        tableDataResumen[i].neto,
        tableDataResumen[i].siva,
        tableDataResumen[i].sila,
        tableDataResumen[i].sretencion,
        tableDataResumen[i].sdescuento,
        tableDataResumen[i].descuentoglobal,
        tableDataResumen[i].srecargo,
        tableDataResumen[i].recargoglobal,
        tableDataResumen[i].total,
      ];
      dataPdf = [...dataPdf, fila];

    };

    return dataPdf;

  }

  const get_column_to_pdf_resumen = () => {
    let columnPdf = [];

    for (let i = 0; i < columnsResumen.length; i++) {

      columnPdf = [...columnPdf, columnsResumen[i].header];

    };

    return columnPdf;
  }


  const downloadPdf = () => {
    const doc = new jsPDF({
      fontSize: 12,
      orientation: 'l',
      unit: 'mm',
      format: 'a4',
      //putOnlyUsedFonts: true
    })

    autoTable(doc, { html: '#my-table' })


    doc.setFontSize(12);
    doc.text(`${title} ${valueMes} ${valueAno}`, 15, 10);
    doc.setFontSize(8);
    doc.text(`${valueParametroEmpresa}`, 15, 13);

    autoTable(doc, {
      styles: {},
      fontSize: 6,
      
      head: [get_column_to_pdf()],
      body: get_data_to_pdf(),

    })

    //doc.text("Resumen");
    doc.setFontSize(8);
    doc.text("Resumen", 15, doc.autoTable.previous.finalY + 5);
    autoTable(doc, {
      styles: {},
      fontSize: 6,
      
      head: [get_column_to_pdf_resumen()],
      body: get_data_to_pdf_resumen(),

    })
    doc.save('table.pdf')
  };


  const cargaEncabezadoDocumento = async (periodo) => {

    setIsLoading(true);
    setIsRefetching(true);
    console.log("periodo ", periodo);

    const urlapi = `${URL_BASE}${API_SEL_EDO_All_REL}${APP_API_SEL_EDO_PER}${periodo}`

    console.log(urlapi);
    await axios
      .get(urlapi, {
        headers: {
          Authorization: `Bearer  ${token}`
        }
      })
      .then(({ data }) => {

        //console.log("tableParametroLibro", tableParametroLibro);

     

        // const [documentName, setDocumentName] = useState([]);

        // const [entName, setEntName] = useState([]);

         //dejando solo documentos seleccionados
        let documentFilter = [];
        for (const nameDocu of documentName) {

          console.log(nameDocu);

          if (entName.map(el => el).indexOf(nameDocu.descripcion) > -1) {
            console.log("lo encontro :",nameDocu);
            documentFilter.push(nameDocu);

          };

        };


        console.log("documentos seleccionados a incorporar ",documentFilter);
        console.log("datos a filtrar :", data.data);
        let dataFilter = [];
        for (const element of data.data) {

          console.log("movimiento a evaluar:",element);

          if (documentFilter.map(el => el.id).indexOf(element.documento.id) > -1) {
            console.log("lo encontro ",element);
            dataFilter.push(element);

          };

        };

        // if (tableParametroLibro.map(el => el.documento.id).indexOf(element.documento.id) > -1) {
        //   console.log("lo encontro ");
        //   dataFilter.push(element);

        // };


        console.log("datos filtrados en documentos", dataFilter);

        setTableData([...dataFilter]);

        console.log("resumen");
        let sumTotalT = 0;
        let sumExentoT = 0;
        let sumNetoT = 0;
        let sumIvaT = 0;

        let sumCantidad=0;
        let sumIlaT = 0;
        let sumRetencionT = 0;
        let sumDescuentoT = 0;
        let sumDescuentoGlobalT = 0;
        let sumRecargoT = 0;
        let sumRecargoGlobalT = 0;

        let dataResumen = [];
        for (const element of documentFilter) {
          // const s=element.operacion.signo
          // const n='1'
          // const c=`${s}${n}`
          // const factor=Number(c);
          const factor=1;

          console.log("documento a agrupar ",element);

          const todosTipoDocFilter = dataFilter.filter((tipo) => tipo.documento.id === element.id);

          console.log("tipo doc ", element.descripcion, "   ", todosTipoDocFilter);

          let sumTotal = 0;
          let sumExento = 0;
          let sumNeto = 0;
          let sumIva = 0;
          
          let sumIla = 0;
          let sumRetencion = 0;
          let sumDescuento = 0;
          let sumDescuentoGlobal = 0;
          let sumRecargo = 0;
          let sumRecargoGlobal = 0;

          //suma tipo documento
          todosTipoDocFilter.forEach((item) => {
            sumCantidad+=1;
            sumTotal += Number(item.total)*factor;
            sumNeto += Number(item.neto)*factor;
            sumExento += Number(item.exento)*factor;
            sumIva += Number(item.siva)*factor;

            sumIla += Number(item.sila)*factor;
            sumRetencion +=Number(item.sretencion)*factor;
            sumDescuento += Number(item.sdescuento)*factor;
            sumDescuentoGlobal += Number(item.descuentoglobal)*factor;
            sumRecargo += Number(item.srecargo)*factor;
            sumRecargoGlobal += Number(item.recargoglobal)*factor;

  
          });
       
          const dataSumas = {

            id: element.id,
            cantidad:sumCantidad,
            documento: element,
            exento: sumExento,
            neto: sumNeto,
            siva: sumIva,

            sila: sumIla,
            sretencion: sumRetencion,
            sdescuento:  sumDescuento,
            descuentoglobal: sumDescuentoGlobal,
            srecargo: sumRecargo,
            recargoglobal:sumRecargoGlobal,

            total: sumTotal
          };

          dataResumen.push(dataSumas);
          console.log("agregando resumen ..........", dataSumas);


          setTableDataResumen([...dataResumen]);
          
          // //suma totales
          // sumTotalT += sumTotal;
          // sumNetoT += sumNeto;
          // sumExentoT += sumExento;
          // sumIvaT += sumIva;

          // sumIlaT += sumIla;
          // sumRetencionT +=sumRetencion;
          // sumDescuentoT += sumDescuento;
          // sumDescuentoGlobalT += sumDescuentoGlobal;
          // sumRecargoT += sumRecargo;
          // sumRecargoGlobalT += sumRecargoGlobal;  


        };


        
        // const dataSumaTotal = {

        //   id: 99999,
        //   documento:{id:'9' ,descripcion:'Totales:'},
        //   exento: sumExentoT,
        //   neto: sumNetoT,
        //   siva: sumIvaT,

        //   sila: sumIlaT,
        //   sretencion: sumRetencionT,
        //   sdescuento:  sumDescuentoT,
        //   descuentoglobal: sumDescuentoGlobalT,
        //   srecargo: sumRecargoT,
        //   recargoglobal:sumRecargoGlobalT,

        //   total: sumTotalT
        // };

        // dataResumen.push(dataSumaTotal);
        // console.log("agregando total a resumen ..........", dataSumaTotal);


        // setTableDataResumen([...dataResumen]);

        setIsLoading(false);
        setIsRefetching(false);

      })
      .catch((error) => {
        console.log("error")
        setErrorPrueba(true)
        setErrorText(JSON.stringify(error))

      });

  };

  // const cargaParametroLibro = async () => {
  //   await axios
  //     .get(`${URL_BASE}${API_SEL_LCO}`, {
  //       headers: {
  //         Authorization: `Bearer  ${token}`
  //       }
  //     })
  //     .then(({ data }) => {

  //       console.log(data.data);



  //       setTableParametroLibro([...data.data]);

  //       setIsLoading(false);
  //       setIsRefetching(false);

  //     })
  //     .catch((error) => {
  //       console.log("error")
  //       setErrorPrueba(true)
  //       setErrorText(JSON.stringify(error))

  //     });

  // };

  const onClickSend = () => {
    console.log("AÑO ", valueAno);
    console.log("MES", valueMes);

    const foundMes = mesesNumero.find((element) => String(element.mes) === valueMes);

    console.log("foundMes ", foundMes);

    console.log("entName ", entName);

    cargaEncabezadoDocumento(`${valueAno}${foundMes.numeromes}`);
  };

  const getParametro = async () => {

    await axios
      .get(`${URL_BASE}${API_SEL_PAR}`, {
        headers: {
          Authorization: `Bearer  ${token}`
        }
      })
      .then(({ data }) => {

        console.log("parametros", data.data[0]);

        setValueParametroEmpresa(data.data[0].empresa);



      })
      .catch((error) => {
        console.log("error")
        setErrorPrueba(true)
        setErrorText(JSON.stringify(error))

      });

  };

  const getDocumentos= async () => {

    await axios
      .get(`${URL_BASE}${API_SEL_DOC}`, {
        headers: {
          Authorization: `Bearer  ${token}`
        }
      })
      .then(({ data }) => {

        console.log("documentos", data.data);

        setDocumentName([...data.data]);

        setIsLoading(false);
        setIsRefetching(false);

      })
      .catch((error) => {
        console.log("error")
        setErrorPrueba(true)
        setErrorText(JSON.stringify(error))

      });

  };
  useEffect(() => {
    setIsLoading(true);
    setIsRefetching(true);

   setTitulomod('');
    setTitle("tipo documento");
    setSubTitle("");

    getParametro(); 
   // cargaParametroLibro();
    getDocumentos();

  }, []);
  return (
    <>

      {errorPrueba &&
        <Alert onClose={() => setErrorPrueba(false)} variant="outlined" severity="error">
          {errorText} This is an error alert — check it out!
        </Alert>
      }

      <Snackbar open={openSnack}
        autoHideDuration={3000}
        onClose={handleClose}

        anchorOrigin={{ vertical, horizontal }}
        key={vertical + horizontal}>
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>

      <Box m="20px">
        <Header
          title={`Informe ${title} ${valueMes} ${valueAno}`}
          subtitle={subTitle}
        />

      </Box>
      <Box m="20px" sx={{ flexGrow: 1 }}>

        < Grid container spacing={3}>
            <Grid item xs={0}>
                <MesAnio
                  valueMes={valueMes}
                  setValueMes={setValueMes}

                  valueAno={valueAno}
                  setValueAno={setValueAno}

                  valueAAno={valueAAno}
                  setValueAAno={setValueAAno}
                />
              </Grid>
              <Grid item xs={1}>
                  <DocumentTag
                      title={title}
                      names={documentName.map((nam)=>nam.descripcion)}
                      entName={entName}
                      setEntName={setEntName}
                  />
              </Grid>

        </Grid>
      </Box>


      <Box m="20px" sx={{ width: '95%' }}>
        <MaterialReactTable
          displayColumnDefOptions={{
            'mrt-row-actions': {
              muiTableHeadCellProps: {
                align: 'center',
              },
              size: 120,
            },
          }}
        
          columns={columns}
          data={tableData}
          enableColumnActions={false}
          localization={MRT_Localization_ES}
          enableTopToolbar={true} //hide top toolbar
          enableBottomToolbar={true} //hide bottom toolbar
          renderToolbarInternalActions={({ table }) => (
            <>
              <ActionIcon
                onClick={() => {

                  //window.print();
                  downloadPdf();

                }}
              >
                <Print />
              </ActionIcon>

              <ActionIcon
                onClick={() => {
                  handleExportData();
                }}
              >
                <CloudDownload />
              </ActionIcon>

              {/* built-in buttons (must pass in table prop for them to work!) */}
              <MRT_ToggleGlobalFilterButton table={table} />
              <MRT_ShowHideColumnsButton table={table} />
              <MRT_FullScreenToggleButton table={table} />
            </>
          )}

          //editingMode="modal" //default
          enableColumnOrdering
          //enableEditing
          state={{
            isLoading,
            showProgressBars: isRefetching
          }}


          renderTopToolbarCustomActions={() => (
            <Box m="20px">
              <Button
                color="secondary"
                disabled={valueMes && valueAno && entName.length>0? false : true}
                onClick={onClickSend}
                variant="contained"
              >
                <Send />
              </Button>

            </Box>


          )}
        />
      </Box>
      {/*resumen  */}
      <Box m="20px">
        <Typography variant="h10" component="div" style={{ flexGrow: 1 }}>
          Resumen
        </Typography>
      </Box>
      <Box m="20px" sx={{ width: '82%' }}>
        <MaterialReactTable
          displayColumnDefOptions={{
            'mrt-row-actions': {
              muiTableHeadCellProps: {
                align: 'center',
              },
              size: 120,
            },
          }}
          columns={columnsResumen}
          data={tableDataResumen}
          //enableColumnActions={false}
          localization={MRT_Localization_ES}
          enableTopToolbar={false} //hide top toolbar
          enableBottomToolbar={true} //hide bottom toolbar
          // renderToolbarInternalActions={({ table }) => (
          //   <>
          //     <ActionIcon
          //       onClick={() => {

          //         // window.print();
          //         downloadPdf();

          //       }}
          //     >
          //       <Print />
          //     </ActionIcon>

          //     <ActionIcon
          //       onClick={() => {
          //         handleExportData();
          //       }}
          //     >
          //       <CloudDownload />
          //     </ActionIcon>

          //     {/* built-in buttons (must pass in table prop for them to work!) */}
          //     {/* <MRT_ToggleGlobalFilterButton table={table} />
          //     <MRT_ShowHideColumnsButton table={table} />
          //     <MRT_FullScreenToggleButton table={table} /> */}
          //   </>
          // )}

          //editingMode="modal" //default

          enableColumnOrdering
          //enableEditing
          state={{
            isLoading,
            showProgressBars: isRefetching
          }}


          renderTopToolbarCustomActions={() => (
            <Box m="20px">
              <Typography variant="h6" component="div" style={{ flexGrow: 1 }}>
                Resumen
              </Typography>
            </Box>


          )}
        />
      </Box>
    </>
  );
};



export default InformeTipoDocumento;
